import React, { useState } from "react"
import { motion } from "framer-motion"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { newsItemHolder } from "./NewsItem.module.scss"

export default function NewsItem({ newsItem, index }) {
  const [hover, setHover] = useState("notHover")
  const image = newsItem.featuredImage
    ? getImage(newsItem.featuredImage.node.localFile)
    : null

  console.log(image)

  const hoverVariants = {
    notHover: {
      scale: 1,
      transition: {
        duration: 0.4,
        type: "tween",
        easing: "easeInOut",
      },
    },
    hover: {
      scale: 1.1,
      transition: {
        duration: 0.25,
        type: "tween",
        easing: "easeInOut",
      },
    },
  }

  return (
    <article
      className={newsItemHolder}
      onMouseEnter={() => setHover("hover")}
      onMouseLeave={() => {
        setHover("notHover")
      }}
      role="listitem"
    >
      {newsItem.newsLink.url && (
        <a
          className="link"
          href={newsItem.newsLink.url}
          target="_blank"
          rel="noreferrer"
        >
          <div className="featuredImageHolder">
            <motion.div
              className="featuredImage"
              variants={hoverVariants}
              initial="notHover"
              animate={hover}
            >
              {image ? (
                <GatsbyImage image={image} alt={newsItem.title} />
              ) : (
                <StaticImage
                  src="../../images/defaultNewsImage.jpg"
                  alt={newsItem.title}
                />
              )}
            </motion.div>
          </div>
          <h3>{newsItem.title}</h3>
          {newsItem.excerpt && (
            <div
              className="excerpt"
              dangerouslySetInnerHTML={{ __html: newsItem.excerpt }}
            />
          )}
          {(newsItem.newsLink.publication || newsItem.newsLink.publication) && (
            <footer>
              <div className="publication">{newsItem.newsLink.publication}</div>
              {newsItem.newsLink.url && (
                <div className="link">Read Article</div>
              )}
            </footer>
          )}
        </a>
      )}
    </article>
  )
}
